.Button360Partner {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);

  font-weight: 600;
  text-align: center;
  vertical-align: middle;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.Button360Partner:hover,
.Button360Partner:active {
  color: var(--white);
  background-color: var(--primary-75);
  border-color: var(--primary-75);
}

.Button360Partner:disabled {
  opacity: 0.65;
  background-color: var(--primary-25);
  border-color: var(--primary-25);
  pointer-events: none;
}

.Button360PartnerError {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
