/* === ESTILOS DO SHOPPING PARA TABLETS === */
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  /* === MENU AND CONTENT CONTAINERS === */
  .shoppingContentContainer {
    flex: 3;
  }

  /* === SHOPPING FORMS PAGES === */
  .catalogForm {
    height: 90%;
  }

  .shoppingTableContainer {
    height: 75%;
  }
}

/* === ALTERAÇÃO DAS TABELAS DO SHOPPING PARA DISPOSITIVOS MENORES DE 768px  === */
@media only screen and (max-width: 768px) {
  .shoppingTableContainer {
    overflow-y: auto;
  }
  .shoppingTableContainer .table-responsive {
    overflow: scroll;
  }

  .tableEllipsis {
    width: auto;
    max-width: 100%;
  }

  .shoppingTableContainer table,
  .shoppingTableContainer thead,
  .shoppingTableContainer tbody,
  .shoppingTableContainer th,
  .shoppingTableContainer td,
  .shoppingTableContainer tr {
    display: block;
  }

  .shoppingTableContainer thead tr {
    display: none;
  }

  .shoppingTableContainer tr {
    padding: 0.25rem;
  }

  .shoppingTableContainer td {
    /* Behave  like a "row" */
    position: relative;
    padding-left: 50% !important;
  }
  .shoppingTableContainer td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;

    font-weight: bold;
  }
  /* PRODUCTS LIST HEADER */
  .shoppingProducts td:nth-of-type(1):before {
    content: 'Código';
  }
  .shoppingProducts td:nth-of-type(2):before {
    content: 'Produto';
  }
  .shoppingProducts td:nth-of-type(3):before {
    content: 'Preço';
  }
  .shoppingProducts td:nth-of-type(4):before {
    content: 'Quantidade';
  }
  .shoppingProducts td:nth-of-type(5):before {
    content: 'Categoria';
  }
  .shoppingProducts td:nth-of-type(6):before {
    content: 'Opções';
  }

  /* CATEGORIES LIST HEADER */
  .shoppingCategories td:nth-of-type(1):before {
    content: 'Nome';
  }
  .shoppingCategories td:nth-of-type(2):before {
    content: 'Descrição';
  }
  .shoppingCategories td:nth-of-type(3):before {
    content: 'Ações';
  }

  /* ORDERS LIST HEADER */
  .shoppingOrders td:nth-of-type(1):before {
    content: 'ID';
  }
  .shoppingOrders td:nth-of-type(2):before {
    content: 'Cliente';
  }
  .shoppingOrders td:nth-of-type(3):before {
    content: 'Status';
  }
  .shoppingOrders td:nth-of-type(4):before {
    content: 'Data';
  }
  .shoppingOrders td:nth-of-type(5):before {
    content: 'Pagamento';
  }
  .shoppingOrders td:nth-of-type(6):before {
    content: 'Valor';
  }
  .shoppingOrders td:nth-of-type(7):before {
    content: 'Método';
  }
  .shoppingOrders td:nth-of-type(8):before {
    content: 'Opções';
  }

  /* PAYMENTS LIST HEADER */
  .shoppingPayments td:nth-of-type(1):before {
    content: 'ID';
  }
  .shoppingPayments td:nth-of-type(2):before {
    content: 'Cliente';
  }
  .shoppingPayments td:nth-of-type(3):before {
    content: 'Status';
  }
  .shoppingPayments td:nth-of-type(4):before {
    content: 'Data';
  }
  .shoppingPayments td:nth-of-type(5):before {
    content: 'Pagamento';
  }
  .shoppingPayments td:nth-of-type(6):before {
    content: 'Método';
  }
  .shoppingPayments td:nth-of-type(7):before {
    content: 'Valor';
  }
}

/* === ESTILOS DE SHOPPING PARA DISPOSITIVOS MÓVEIS ===*/
@media only screen and (max-width: 600px) {
  /* === MAIN CONTAINER === */
  .shoppingContainer {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  /* === MENU AND CONTENT CONTAINERS === */
  .disabledMenu {
    margin: 1rem;
    width: auto;
  }

  .shoppingContentContainer {
    flex: 9;

    margin: 0.25rem;
    padding: 0.25rem;
    /*width: -webkit-fill-available;*/
    overflow: hidden;
  }

  /* === SHOPPING FORMS PAGES === */
  .shoppingFormContainer {
    padding: 0.5rem;
  }

  .catalogForm {
    height: 1vh;
  }

  .buttonsCenterContainer {
    margin: 0;
    flex-direction: column;
  }

  .buttonsCenterContainer button + button {
    margin-top: 1rem;
  }

  /* === SHOPPING TABLES PAGES === */
  .containerMobile {
    overflow-y: auto;
  }

  .shoppingPaginationContainer {
    margin: 0;
  }
  .shoppingPaginationContainer .pagination {
    font-size: 80%;
  }

  /* = TABLES = */
  .shoppingTableContainer {
    height: 100%;
  }
}

/*=== MEDIA QUERIES PARA CELULARES MENORES QUE 325px de largura === */
/*iphone 5s*/
@media only screen and (max-width: 325px) {
}
