.checkbox_container {
  display: flex;
  flex-direction: column;
}

.custom_button {
  margin: 20px 0 0;
}

.buttons_container {
  margin: 20px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.image_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image_header img {
  max-width: 50%;
  object-fit: contain;
  object-position: top center;
}