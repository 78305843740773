/* Override Modal Style from flamboyant.css */

.modal_title {
  display: flex !important;
}

.modal_title h5 {
  font-size: 1rem;
}

.modal_title p {
  padding: 0px 0.5rem;
  margin: 0;
}

.modal_title button {
  color: var(--danger);
  background: none;
  border: none;
  width: 32px;
  height: 32px;
}

.modal_body {
  padding: 0;
  margin: 0;
}

/* Phone layout*/
.container {
  min-height: 300px;
  max-height: 500px;

  display: flex;
  flex-direction: column;
}

.header {
  border-radius: 1rem 1rem 0px 0px;
  background: #075e55;
  padding: 0.5rem;
  height: 56px;

  display: flex;
  align-items: center;
  justify-items: start;
}

.header span {
  border-radius: 100%;
  background-color: white;
  color: black;
  width: 36px;
  height: 36px;

  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.body {
  display: flex;
  padding: 16px 16px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #eee5dc;
  flex-grow: 2;
  margin-bottom: -1px;
  overflow: auto;
}

.body::-webkit-scrollbar {
  width: 0.3rem;

}

.body::-webkit-scrollbar-thumb {
  background: #7777776b;
  border-radius: 3px;
}

.body::-webkit-scrollbar-thumb:hover {
  background: #7777778b;
}

.ballon {
  display: flex;
  background: #fff;
  border-radius: 0px 10px 10px;
  padding: 0.3rem;
  max-width: 70%;
  margin-bottom: 8px;
}

.ballon::before {
  border-left: 5px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid #fff;
  border-color: transparent #fff transparent transparent;
  content: '';
  left: -20px;
  position: relative;
  top: -4px;
  height: 0;
  z-index: 1;
  margin-right: -15px;
}

.footer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-top: auto;
  padding: 3px 20px 8px;
  bottom: 0px;
  left: 0px;
  background: #eee5dc;
  border-radius: 0px 0px 1rem 1rem;
}

.footer div {
  display: flex;
  width: 100%;
  padding: 9px 16px;
  justify-content: flex-end;
  align-items: center;

  border-radius: 480px;
  background: #fff;
}

.footer div span {
  margin-right: auto;
}

.footer > span {
  display: flex;
  width: 2rem;
  height: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  background: #00887c;
  color: white;
}

/*
  Template details
*/

.ballon p {
  color: #606161;
  padding: 0.25rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  line-break: auto;
  text-align: start;
  font-weight: 500;
}

.ballon span {
  color: #606161;
  padding: 0.25rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  line-break: auto;
  white-space: break-space;
}

.header_text {
  color: #616161;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.header_media {
  width: 100%;
  max-height: 450px;
}

.header_media img,
.header_media video {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 8px;
}

.button {
  width: 70%;
  color: blue;

  background-color: white;

  border-radius: 0.4rem;
  padding: 0.2rem 0.5rem;
  margin-bottom: 0.2rem;
  height: 2.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}

/* Media Query */
@media (max-width: 500px) {
  .container {
    max-height: 400px;
    overflow: hidden;
  }

  .body {
    max-height: 80%;
  }

  .ballon {
    max-width: 90%;
  }

  .button {
    width: 90%;
  }

  .header_media {
    width: 100%;
    max-height: 300px;
  }
}
