.container360OnboardPage {
  margin: 1rem;
}

.containerButtons360Onboard {
  margin-top: 2rem;
}

.infoTitle {
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.containerButton360Partner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerLoading360Partner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.loading360Partner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.loading360Partner > div {
  width: 42px;
}
