.content_flex {
  height: 100%;
  width: 100%;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.drop_full {
  height: 100%;
}

.cancel_button {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  padding: 0px;
  display: flex;
  border: 1px solid var(--primary);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}

.cancel_button:focus {
  border: 1px solid var(--primary);
}

.input_container {
  position: relative;
}

.input_container input {
  padding-right: 135px;
  padding-left: 45px;
  background: var(--textbox-color) !important;
  border: none !important;
  /* Para dar espaço ao ícone */
  width: 100%;
}

.input_container input:focus {
  color: var(--tertiary-font-color) !important;
}

.input_container .emoji {
  position: absolute;
  top: 20px;
  /* Ajuste conforme necessário */
  transform: translateY(-50%);
}

.input_container .icon {
  position: absolute;
  top: 19px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px var(--primary) solid;
  color: var(--primary);
  border-radius: 4px;

  right: 100px;
  /* Ajuste conforme necessário */
  transform: translateY(-50%);
}

.input_container .send_button {
  position: absolute;
  border: 1px var(--primary) solid;
  color: var(--primary);
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  top: 19px;
  right: 5px;
  /* Ajuste conforme necessário */
  transform: translateY(-50%);
}

.input_container .send_button button {
  display: flex;
}

.input_container .emoji i {
  color: var(--primary);
}

@media only screen and (max-width: 600px) {
  .content_flex {
    height: 90%;
    margin-top: 52px;
    margin-bottom: 10px;
    gap: 0.25rem;
  }
  .input_container {
    padding: 0 0.5rem;
  }

  .input_container .icon {
    right: calc(2.5rem + 50px);
  }

  .input_container .send_button {
    right: 15px;
  }

  .input_container input {
    padding-right: 115px;
  }

  .content_flex ul {
    margin-bottom: 0;
  }
}
