.selector_container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 12px;
  border-radius: 5px;
  border: 2px solid var(--green-polichat);
  background-color: var(--quick-selector-background);
}

.list_container {
  min-height: 5rem;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.content_container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.content_container div {
  font-size: 2rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

.selector_header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.header_text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 90%;

  color: var(--contact-color-font);
  padding-right: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 123.5%;
  letter-spacing: 0.25px;
}

.header_fields {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 12px;
}

.empty_results div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0.25rem;
}

.empty_results div > span {
  background-color: #fff;
  border-radius: 100%;
  padding: 5px;
  text-align: center;
  justify-content: center;
  color: var(--primary);
}

.empty_results i {
  color: var(--primary);
}

@media only screen and (max-width: 600px) {
  .list_container {
    max-height: 200px;
  }
  .header_text {
    display: none;
  }
  .form_container {
    gap: 4px;
  }
}
