.container {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  padding: 8px 0;
  gap: 8px;

  flex: 0 0 auto;
}

.text {
  width: 100%;
  padding: 8px;
  background: transparent;
  border: none;
  color: var(--textDefault);
  border-radius: 4px;

  display: flex;
  flex-direction: column;
}

.text:focus {
  outline: none; /* remover borda ao redor do botão quando clica */
}

.text_name {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  font-size: 16px;
}

.text:hover,
.text__active {
  background-color: var(--active-channel-selector);
}

.text__active {
  border: 1px solid var(--green-polichat);
}

/* Remove espaço à esquerda padrão da classe */
.text_name__type,
.text_name__type span {
  margin-left: 0 !important;
}

.text_name_shortcut {
  font-style: italic;
}

.text_content,
.text_name__title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  white-space: pre-wrap;
  word-break: break-word;
  text-align: left;
  padding-right: 8px;
  font-size: 14px;
}

.text_name__title {
  font-weight: bold;
  margin-left: 0 !important;
  -webkit-line-clamp: 1;
}

.control {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.control_edit,
.control_preview {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 8px;
  height: 100%;
}

.control_remove {
  color: var(--danger);
}

.control__button {
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .control {
    flex-direction: column;
  }

  .control_edit,
  .control_preview {
    flex-direction: column;
  }
}
