.startContent:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  opacity: 0.05;
  background-image: var(--chat-background-default);
  background-color: transparent;
  background-size: 60%;
  z-index: 0;
  top: 0;
  left: 0;
}
.startContent {
  display: flex;
  flex: 1 1;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-background-color);
  border: 1px solid var(--border-color);
  margin-left: 10px;
  border-radius: 5px;
  background-size: contain;
}

/*Usado para esconder o segundo startContent*/
.startContent + .startContent {
  display: none;
}

.itemStartContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80% !important;
}

.itemStartContent h4 {
  text-align: center;
  color: var(--secondary-font-color);
}
