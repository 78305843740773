.container360EditPage {
  margin: 1rem;
}

.loadingAnimation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inputLabelIcon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.3rem;
}

.inputSwitchText {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.inputErrorFeedBack {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--danger);
}
